.impressum-content section {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.impressum-content section:last-of-type {
    border-bottom: none;
}

.impressum-content p.mb-0 + p.mb-0 {
    margin-top: 0.2rem;
}

.impressum-content .update-info {
    color: var(--color-muted-element);
    font-size: 0.9rem;
    margin-top: 3rem;
}

.impressum-content h1:first-child {
    margin-top: 0;
}