#skill-spider-chart > *{
    max-width: 270px;
}

@media (min-width: 480px){
    #skill-spider-chart > *{
        max-width: 330px;
    }
}


@media (min-width: 576px){
    #skill-spider-chart > *{
        max-width: 400px;
    }
}


@media (min-width: 768px){
    #skill-spider-chart > *{
        max-width: 100%;
    }
}