/* CopyableObfuscatedEmail component styles */
.copyable-email {
    cursor: pointer;
}

.copyable-email-container {
    display: inline-block;
}

.copy-notification {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-highlight);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
    z-index: 10;
    white-space: nowrap;

    & i {
        font-size: 0.875rem;
    }
}
