:root {
    --color-text: #333333;
    --color-background: #FFFFFF;
    --color-highlight: #1E3A8A;
    --color-muted-element: #6B7280;
    --color-muted-border: #d5d5d5;
    --color-muted-background: #F3F3F3;
    --color-link-color: #1A237E;
    --color-link-hover-color: var(--color-highlight);
    --color-availability-available: #1a9e51;
    --color-availability-restricted: #F59E0B;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, sans-serif;
    background-color: var(--color-background);
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.7;
    text-align: left;
}

li {
    text-align: left;
}

html {
    /* Start with 14px on mobile devices while our base font is 16px */
    font-size: 87.5%;
}



@media screen and (min-width: 768px) {
    html {
        font-size: 100%; /* 16px */
    }

    body {
        text-align: justify;
    }
}

@media screen and (min-width: 992px) {
    html {
        font-size: 110%; /* 17.5px */
    }
}

@media screen and (min-width: 1440px) {
    html {
        font-size: 120%; /* 19px */
    }
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    margin: 1.5em 0 0.5em;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
  font-style: italic;
}

h5 {
  font-size: 1.1rem;
  font-weight: bold;
}

h6 {
  font-size: 1.1rem;
  font-style: italic;
}