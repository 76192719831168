/* Back to top */
.back-to-top-btn button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: solid 1px var(--color-muted-element);
    background-color: var(--color-muted-background);
    text-align: center;
}

.back-to-top-btn img{
    width: 20px;
}

/* Footer overall */
.footer-wrapper {
    background-color: var(--color-muted-background);
    border-top: solid 1px var(--color-muted-element);

    & * {
        text-align: justify;
    }
}

.footer-wrapper * {
    font-size: 11px;
}

/* Social icons */
.social-icons--footer img {
    height: 20px;
    transition: opacity 1s ease;
}

.social-icons--footer {
    padding: 60px 0;
    gap: 40px
}

/* Main content */
.footer-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px;
}

.footer-text {
    font-size: 12px;
    line-height: 1.7;
}

/* Language Switch */
.footer-language-switch p {
    margin: 0;
}

.footer-language-switch {
    gap: 7px;
    margin-bottom: 25px;
}

.footer-language-switch img {
    max-width: 12px;
}

/* Copyright & Datenschutz */

.footer-copyright {
    color: var(--color-muted-element);
}

.footer-link a {
    color: inherit;
    text-decoration: none;
}


@media (max-width: 576px) {

    .social-icons--footer {
        padding: 60px 0;
        gap: 50px
    }

}