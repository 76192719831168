.privacy-policy-content section {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.privacy-policy-content section:last-of-type {
    border-bottom: none;
}

.privacy-policy-content p.mb-0 + p.mb-0 {
    margin-top: 0.2rem;
}

/* List styling */
.privacy-policy-content ul {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.privacy-policy-content li {
    margin-bottom: 0.5rem;
}

.privacy-policy-content .update-info {
    color: var(--color-muted-element);
    font-size: 0.9rem;
    margin-top: 3rem;
}

.privacy-policy-content h1:first-child {
    margin-top: 0;
}