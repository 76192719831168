/* Base styles (mobile first) */

.hero-content-container {
    padding: 0 1.75rem;
}

.main-template-hero {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    overflow: visible;
}

.hero-content-row {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.hero-text-column {
    height: calc(350px / 3);
}

.hero-image-column {
    height: calc((350px / 3)*2);
}

.hero-text-column {
    text-align: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    & h1 {
        font-size: 1.75rem;
    }
}

.hero-title {
    font-weight: bold;
}

.hero-tagline {
    font-size: 1.1rem;
    padding-top: 0.5rem;
}

.hero-image-column {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: hidden;
}

.hero-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 0 0 2rem 0;
}

/* Lottie Animation Styles */
.hero-lottie-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 2rem 0;
}

.hero-lottie {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    padding: 0 0 2rem 0;
}

/* Small devices (576px and up) */
@media (min-width: 576px) {
    .hero-content-container {
        max-width: min(1440px, 100vw);
        margin: 0 auto;
    }

    .hero-text-column h1 {
        font-size: 2rem;
    }

    .hero-tagline {
        font-size: 1.25rem;
    }

    .main-template-hero {
        height: 400px;
    }

    .hero-text-column {
        height: calc(400px / 3);
    }

    .hero-image-column {
        height: calc((400px / 3)*2);
    }
}

/* Medium devices (811px and up) */
@media (min-width: 811px) {
    .main-template-hero {
        height: 500px;
    }

    .hero-text-column {
        height: calc(500px / 3);
    }

    .hero-image-column {
        height: calc((500px / 3)*2);
    }
}

/* Large devices (996px and up) */
@media (min-width: 996px) {

    .hero-content-container {
        padding: 0 4rem;
    }

    .hero-lottie-container {
        padding: 0;
    }

    .main-template-hero {
        height: 550px;
    }

    .hero-content-row {
        flex-direction: row;
        align-items: center;
    }

    .hero-text-column {
        min-width: 300px;
        text-align: left;
        height: 450px;
    }

    .hero-image-column {
        width: auto;
        justify-content: flex-end;
        height: 350px;
        margin-left: auto;
    }

    .hero-image {
        padding: 0;
    }

    .hero-lottie {
        padding: 0;
    }
}

/* Large devices (1200px and up) */
@media (min-width: 1200px) {
    .hero-content-container {
        padding: 0 8rem 0 6rem;
    }

    .hero-image-column {
        height: 400px;
        margin-left: auto;
    }
}