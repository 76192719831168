/* Whole navigation bar*/
.navbar {
    transition: transform 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    height: var(--header-height);
}

.nav-visible {
    transform: translateY(0);
}

.nav-hidden {
    transform: translateY(-100%);
}

.nav-scrolled {
    border-bottom: 1px solid var(--color-muted-element);
}

/* logo */
.logo {
    max-height: 2.5rem;
}

/* Menu items sans language switch */
.nav-list {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
    min-width: 0;
}

.nav-list * {
    color: var(--color-text);
}

.nav-link:hover,
.nav-link-active:hover,
.header-language-switch:hover *.inactive-language {
    text-decoration: underline;
    text-underline-offset: 12px;
    text-decoration-thickness: 2px;
    transition: all 0.3s ease;
}

.nav-link-active {
    font-weight: bold;
    color: var(--color-highlight) !important;
    border: 0;
    background: transparent;
}


.contact-btn {
    padding: 0.1rem 1rem;
    border: 2px solid var(--color-highlight);
    color: var(--color-highlight);
    border-radius: 10px;
    background: transparent;
    cursor: pointer;
    font: inherit;
    transition: all 0.3s ease;
}

.contact-btn:hover {
    background-color: var(--color-highlight);
    color: var(--color-background);
}

/* Language switch */
.separator-bar {
    width: 1px;
    height: 35px;
    align-self: center;
    margin: 0 1rem;
    background-color: var(--color-muted-element);
}

.header-language-switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    white-space: nowrap;
    flex-shrink: 0;
}

.header-language-switch img {
    max-height: 1rem;
}

.header-language-switch span {
    font-size: 0.6rem;
}

.full-language-name {
    display: none;
}

.abbreviated-language-name {
    display: flex;
}

.active-language,
.inactive-language {
    transition: color 0.3s ease, opacity 0.3s ease;
}

.active-language {
    color: var(--color-highlight);
    font-weight: bold;
}


/* Menu for small displays*/
#logo-side-menu {
    display: none;
}

@media (max-width: 992px) {
    .offcanvas {
        height: 100vh;
    }

    /* Menu items sans language switch */
    .nav-list {
        align-items: flex-start;
    }

    .nav-list * {
        font-size: 1.3rem;
    }

    .nav-link:hover,
    .nav-link-active:hover,
    .header-language-switch:hover *.inactive-language {
        text-decoration: none;
    }

    .nav-list li {
        width: 100%;
    }

    .contact-btn {
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        border: 0;
        border-radius: 0;
        width: 100%;
        color: var(--color-text);
        text-align: left;
    }

    .contact-btn:hover {
        background-color: inherit;
        color: inherit;
        box-shadow: none;
        transform: none;
    }

    .nav-link-active {
        padding: 8px 0 8px 0;
    }


    /* Language switch */
    .separator-bar {
        width: 100%;
        height: 1px;
        align-self: center;
        margin: 1rem 0;
        background-color: var(--color-muted-element);
    }

    .header-language-switch {
        padding: 1rem 0;
    }

    .header-language-switch span {
        font-size: 1rem;
    }

    .full-language-name {
        display: flex;
    }

    .abbreviated-language-name {
        display: none;
    }
}