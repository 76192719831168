/* ObfuscatedEmail component styles */
.email-obfuscated {
    display: inline-block;
    position: relative;
}

.email-part {
    display: inline-block;
}

/* @ symbol using CSS content */
.at-symbol::before {
    content: '\0040'; /* Unicode for @ symbol */
    display: inline-block;
}

/* Domain part reversed with CSS */
.domain-part {
    display: inline-block;
    direction: rtl;
    unicode-bidi: bidi-override;
}

/* Add more misleading elements for bots */
.email-obfuscated::after {
    content: attr(data-not-email);
    display: none;
}

/* Hidden element to confuse bots */
.email-obfuscated::before {
    content: '';
    display: none;
}