:root {
  --header-height: 3.5rem;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-top: var(--header-height);
}

.content-section-spacer {
  margin-top: 75px;
}

.content-section-inter-spacer {
  margin-top: 45px;
}

.centered-content-container {
  max-width: 992px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.main-container {
  max-width: min(1440px, 100vw);
  padding: 0 2rem;
  margin: 0 auto;
}

.std-btn {
  border-radius: 10px;
  background-color: var(--color-highlight);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  text-align: center;
}

.muted-btn {
  text-align: center;
  background-color: white;
  border: none;
  color: var(--color-highlight);
}

.accordion {
  --bs-accordion-border-color: var(--color-muted-border)
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion {
  padding: 0;
}

.link-primary {
  color: var(--color-link-color);
  text-decoration: none;
}

.link-primary:hover {
  color: var(--color-link-hover-color);
}


@media (max-width: 992px) {
  :root {
    --header-height: 4rem;
  }

}

@media (max-width: 576px) {
  .main-container {
    padding: 0 1.75rem;
  }

  .std-btn {
    padding: 1rem 1.5rem;
  }
}