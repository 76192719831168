.icon-primary {
    color: var(--color-highlight);
    font-size: 2rem;
    display: flex;
    padding-right: 0.5rem;
}

.contact-details {
    & h3 {
        margin-top: 0;
    }
}

#send-email-btn a {
    width: 500px;
}


.availability-card {
    margin-bottom: 2rem;
    border-radius: 10px;
    background-color: var(--color-background);
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    transition: box-shadow 0.3s ease, border-left-color 0.3s ease;
    position: relative;
    border-left: 0.5rem solid var(--color-availability-available);
}

.availability-card.state-open {
    border-left-color: var(--color-availability-available);
    box-shadow: 0 0 10px var(--color-availability-available);
}

.availability-card.state-starting {
    border-left-color: var(--color-availability-available);
    box-shadow: 0 0 10px var(--color-availability-available);
}

.availability-card.state-consultation {
    border-left-color: var(--color-availability-restricted);
    box-shadow: 0 0 10px var(--color-availability-restricted);
}



/*!* Small devices (340px and up) *!*/
/*@media (min-width: 576px) {*/
/*}*/

/*!* Small devices (576px and up) *!*/
/*@media (min-width: 576px) {*/
/*}*/

/*!* Medium devices (811px and up) *!*/
/*@media (min-width: 811px) {*/
/*    #phone-image {*/
/*        padding-bottom: 1rem;*/
/*    }*/
/*}*/

/*!* Large devices (996px and up) *!*/
/*@media (min-width: 996px) {*/
/*    #phone-image {*/
/*        padding: 5rem;*/
/*    }*/
/*}*/

/*!* Large devices (1200px and up) *!*/
/*@media (min-width: 1200px) {*/
/*    #phone-image {*/
/*        padding: 7rem;*/
/*    }*/
/*}*/

/*!* Large devices (1300px and up) *!*/
/*@media (min-width: 1300px) {*/
/*    #phone-image {*/
/*        padding: 8rem;*/
/*    }*/
/*}*/

